// Hamburguer
const hamburguer = document.querySelector('.header__menu-mobile');
const menu = document.querySelector('.header__nav');

hamburguer.addEventListener('click', () => {
    menu.classList.toggle('header__nav--active');
    hamburguer.classList.toggle('header__menu-mobile--active');
})


//Link ancora
$('a[href^="#"]').bind('click.smoothscroll, click touchend', function (e) {
    e.preventDefault();

    var target = this.hash,
        $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 80
    }, 800, 'swing', function () {

    });
});


// faq
const faqItems = document.querySelectorAll('.faq .faq__item');

faqItems.forEach(el => {
    el.querySelector('.faq__header').addEventListener('click', () => {

        if (el.classList.contains('active')) {
            el.classList.remove('active');
        } else {
            faqItems.forEach(element => {
                element.classList.remove('active');
            })
            el.classList.add('active');
        }
    })
})


// Bannres
$(document).ready(function () {
    $('.products__list').slick({
        prevArrow: $('.products__arrow-left'),
        nextArrow: $('.products__arrow-right'),
        autoplay: true,
        slide: '.products__item',
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
        ]
    });
});


let servicos = document.querySelectorAll('.featured-products__item');
servicos.forEach((item, index) => {
    if(index % 2 !== 0 ){
        item.classList.add('featured-products__item--reverse')
    }
})